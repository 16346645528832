<template>
  <div class="_test">
    <section class="_section__hero-wrapper"></section>

    <section class="_section__slider--gallery">
      <h2 class="_title">Gallery</h2>
      <div class="_slider-content">
        <ContentSlider>
          <!-- <div class="_sliderGalleryWrapper" ref="sliderGalleryWrapper"> -->
          <picture
            class="_picture _slider__item"
            v-for="image in gallerySlider"
            :key="image.id"
          >
            <img loading="lazy" class="_image" :src="image.path" alt="Grid Gallery" />
            <div class="_caption">{{ image.caption }}</div>
          </picture>
        </ContentSlider>
      </div>
    </section>

    <section class="_section__slider-option-wrapper">
      <section class="_section__custom--slider-option">
        <AutoSlider></AutoSlider>
      </section>
    </section>

    <section class="_section__custom--simple">
      <div class="_media">
        <picture class="_picture">
          <source
            media="(min-width: 1200px)"
            srcset="@/assets/images/homepage_about-1200.jpg"
          />
          <source
            media="(min-width: 600px)"
            srcset="@/assets/images/homepage_about-Small.jpg"
          />
          <img
            loading="lazy"
            class="_image"
            src="@/assets/images/homepage_about-Small.jpg"
            alt="about"
          />
        </picture>
      </div>
      <div class="_card__custom">
        <h2 class="_title">About - A Riverside Abode amidst Nature</h2>
        <div class="_paragraphs">
          <p class="_text">
            Nature helps us unwind and reconnect with ourselves. The fort-like
            structure of the homestead sits on the banks of the Pej river. The
            calming melody of the river and the greenery of the farm is
            rejuvenating.
          </p>
          <p class="_text">
            Kamal farms expand across 5 acres of fertile farmland. The Riverside
            abode lends itself to guests for small and large gatherings. We
            ensure attentive service, spacious and comfortable accommodation,
            and cuisine based on organic farm produce. Be it a family get
            together, a corporate retreat, or a chic destination wedding, the
            space lends itself for a soothing experience to adults and children
            alike.
          </p>
        </div>
        <div class="_links">
          <router-link :to="{ name: 'About' }" class="_link"
            >About Us</router-link
          >
        </div>
      </div>
    </section>

    <section class="_section__custom--overlap">
      <div class="_media">
        <picture class="_picture">
          <source media="(min-width: 1200px)" srcset="@/assets/riverside.jpg" />
          <source media="(min-width: 600px)" srcset="@/assets/riverside.jpg" />
          <img class="_image" src="@/assets/riverside.jpg" alt="about" />
        </picture>
      </div>
      <div class="_card--raised">
        <h2 class="_title">About - A Riverside Abode amidst Nature</h2>
        <div class="_paragraphs">
          <p class="_text">
            Nature helps us unwind and reconnect with ourselves. The fort-like
            structure of the homestead sits on the banks of the Pej river. The
            calming melody of the river and the greenery of the farm is
            rejuvenating.
          </p>
          <p class="_text">
            Kamal farms expand across 5 acres of fertile farmland. The Riverside
            abode lends itself to guests for small and large gatherings. We
            ensure attentive service, spacious and comfortable accommodation,
            and cuisine based on organic farm produce. Be it a family get
            together, a corporate retreat, or a chic destination wedding, the
            space lends itself for a soothing experience to adults and children
            alike.
          </p>
        </div>
        <div class="_links">
          <a href="#" class="_link">About Us</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AutoSlider from "@/components/AutoSlider.vue";
import ContentSlider from "@/components/ContentSlider.vue";
export default {
  name: "Test",
  data() {
    return {
      testimonies: [
        {
          id: 1,
          name: "Dr. P. B. Kankaria",
          comment:
            "Had a good stay, good food, good hospitality. Would like to come again in future.",
          rating: 4,
        },
        {
          id: 2,
          name: "Deepak Shah",
          comment: "Loved the hospitality and the villa. Thank you.",
          rating: 4,
        },
        {
          id: 3,
          name: "Ameet",
          comment:
            "Property is very well maintained. Food is excellent. Overall a very pleasant and peaceful stay. Much appreciation for the service provided by the caretaker.",
          rating: 4,
        },
        {
          id: 4,
          name: "Moulik Solanki",
          comment:
            "Terrific place and amazing experience. Thank you Sachin & SaffronStays for your pleasant hospitality. A warm dhanyawaad on behalf of the entire group.",
          rating: 4,
        },
        {
          id: 5,
          name: "Ketan Patel",
          comment:
            "Excellent place and team. Falling short of words to express how much we liked it.",
          rating: 4,
        },
      ],
      gallerySlider: [
        {
          id: 1,
          path: require("@/assets/images/luxury_gallery_1-Small.jpg"),
          caption: "Front View",
        },
        {
          id: 2,
          path: require("@/assets/images/luxury_gallery_2-Small.jpg"),
          caption: "Lawn View",
        },
        {
          id: 3,
          path: require("@/assets/images/luxury_gallery_3-Small.jpg"),
          caption: "Living Room First Floor - Overview",
        },
        {
          id: 4,
          path: require("@/assets/images/luxury_gallery_4-Small.jpg"),
          caption: "Living Room First Floor - Seating",
        },
        {
          id: 5,
          path: require("@/assets/images/luxury_gallery_5-Small.jpg"),
          caption: "Living Room First Floor - Dining",
        },
        {
          id: 6,
          path: require("@/assets/images/luxury_gallery_6-Small.jpg"),
          caption: "Bedroom 1",
        },
        {
          id: 7,
          path: require("@/assets/images/luxury_gallery_7-Small.jpg"),
          caption: "Bathroom 1",
        },
        {
          id: 8,
          path: require("@/assets/images/luxury_gallery_8-Small.jpg"),
          caption: "Bedroom 2",
        },
        {
          id: 9,
          path: require("@/assets/images/luxury_gallery_9-Small.jpg"),
          caption: "Bedroom 3",
        },
        {
          id: 10,
          path: require("@/assets/images/luxury_gallery_10-Small.jpg"),
          caption: "Living Room Ground Floor - Seating",
        },
        {
          id: 11,
          path: require("@/assets/images/luxury_gallery_11-Small.jpg"),
          caption: "Living Room First Floor - Table Tennis",
        },
        {
          id: 12,
          path: require("@/assets/images/luxury_gallery_12-Small.jpg"),
          caption: "Bedroom 4",
        },
        {
          id: 13,
          path: require("@/assets/images/luxury_gallery_13-Small.jpg"),
          caption: "Bathroom 4",
        },
        {
          id: 14,
          path: require("@/assets/images/luxury_gallery_14-Small.jpg"),
          caption: "Bedroom 5",
        },
        {
          id: 15,
          path: require("@/assets/images/luxury_gallery_15-Small.jpg"),
          caption: "Outdoor Seating",
        },
        {
          id: 16,
          path: require("@/assets/images/luxury_gallery_16-Small.jpg"),
          caption: "Indoor Games",
        },
        {
          id: 17,
          path: require("@/assets/images/luxury_gallery_17-Small.jpg"),
          caption: "Swing Area",
        },
        {
          id: 18,
          path: require("@/assets/images/luxury_gallery_18-Small.jpg"),
          caption: "Garden & River",
        },
      ],
    };
  },
  components: {
    ContentSlider,
    AutoSlider,
  },
};
</script>

<style scoped lang="scss">
._test {
  //padding: 140px 0 30px;
  // display: grid;
  ._section__slider--gallery {
    @extend ._section__custom;
    ._slider-content {
      max-height: 350px;
    }
  }
  ._section__hero-wrapper {
    // @extend ._section__custom;
    // margin-bottom: 0;
    height: 40vh;
  }
  ._section__slider-option-wrapper {
    @extend ._section--bg;
    background-position: 100% 100%;
    background-size: 80% 80%;
  }
}
._section__custom--slider-option {
  @extend ._section__custom;
}
@media screen and (min-width: 600px) {
  ._test {
    ._section__slider--gallery {
      ._slider-content {
        max-height: 500px;
      }
    }
    ._section__slider-option-wrapper {
      background-size: 60% 80%;
    }
  }
}
@media screen and (min-width: 1200px) {
  ._test {
    ._section__slider--gallery {
      ._slider-content {
        max-height: 600px;
      }
    }
    ._section__slider-option-wrapper {
      background-size: 50% 80%;
    }
  }
}
</style>